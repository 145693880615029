.item a:visited {
    color: #444444;
}
.item:hover {
    background: #eeeeee
}
.item img {
    max-height: 120px;
    max-width: 450px;
}
.desc {
    overflow: auto;
}
.tags {
    font-size: smaller;
}
.controls button {
    border: 0;
    background: transparent;
    padding: 5px;
    margin: 0;
    height: 28px;
    cursor: pointer;
    outline: none;
}
.controls button:hover {
    background: #dbdbdb;
}
.controls a {
    margin: 2px;
}
.controls {
    font-size: smaller
}
.editBtn {
    border: 0;
    background: transparent;
    padding: 5px;
    margin: 0;
    height: 28px;
    cursor: pointer;
    outline: none;
}
.feedName {
    color: #888888;
}