.Sidebar {
    position: absolute;
    margin-top: 10px;
    margin-left: 0;
}
.Sidebar li {
    margin: 0;
    padding: 5px;
}
.Sidebar li:hover {
    background: #eeeeee
}
.Sidebar .active {
    background: #dbdbdb;
}
.Sidebar .active:hover {
    background: #dbdbdb;
}
.Sidebar a {
    margin: 0;
    height: 20px;
    display: block;
}
.categories {
    margin-top: 0;
}