.outer {
    height: 98%;
    padding: 10px;
}
.main {
    margin: 0;
    min-height: 90%;
    margin-left: 100px;
}
.error {
    background: red;
    color: white;
    text-align: center;
    height: 30px;
    font-size: 24px;
    margin-bottom: 5px;
}