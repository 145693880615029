.tagsCont {
    height: 30px;
}
.suggestions {
    position: absolute;
    z-index: 10;
    background: white;
    min-height: 30px;
    min-width: 200px;
    padding: 2px;
    border: 2px solid lightgray;
}
.suggestion {
    display: block;
    cursor: pointer;
    margin: 0;
}